@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.s-quiz-title {
  font-size: 1.5rem;
}
.s-quiz-subheading {
  font-size: 1.3rem;
}
